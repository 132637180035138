@use "ms_variables" as ms;

.milkshake.anim {

    &.error {
        .wave {
            filter: invert(98%) sepia(2%) saturate(2135%) hue-rotate(238deg) brightness(40%) contrast(88%) !important;


        }

        .tache,
        .wave .ondulations {
            animation-play-state: paused !important;
        }
    }

    .chantilly {
        opacity: 0;
        transform: scaleY(0) scaleX(0);
        transition: all .3s ease-in;
        transform-origin: bottom;
    }

    .chantilly_top {
        transition-delay: 0.6s;
    }

    .chantilly_middle {
        transition-delay: 0.3s;
        transform-origin: bottom right;
    }

    .chantilly_bottom {
        transform-origin: bottom left;
    }

    .chantilly_under {
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 1500ms ease-out, opacity 250ms ease-in;
        transition-delay: 800ms;

        &+.chantilly_under {
            transition-delay: 900ms;
        }
    }

    .confetti {

        opacity: 0;
        animation-name: confetti;
        animation-play-state: paused;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-duration: .15s;


        &.confetti1 {
            animation-delay: 0.15s
        }

        &.confetti2 {
            animation-delay: 0.35s
        }

        &.confetti3 {
            animation-delay: 0.5s
        }

        &.confetti4 {
            animation-delay: 0.6s
        }

        &.confetti5 {
            animation-delay: 0.75s
        }

        &.confetti6 {
            animation-delay: 0.4s
        }

        &.confetti7 {
            animation-delay: 0.9s
        }

        &.confetti8 {
            animation-delay: 1s
        }

        &.confetti9 {
            animation-delay: 0.35s
        }

        &.confetti10 {
            animation-delay: 1.2s
        }

        &.confetti11 {
            animation-delay: 1.1s
        }

        &.confetti12 {
            animation-delay: .85s
        }

    }

    .paille {
        opacity: 0;
    }

    .tache {
        opacity: 0;
        animation-name: tache, tache_end;
        animation-play-state: paused, paused;
        animation-iteration-count: infinite, 1;
        animation-timing-function: linear;
        animation-fill-mode: both, forwards;
        animation-duration: 7s, .35s;
        animation-delay: 3s, 0.75s;
    }


    .wave {
        transition: all 1s ease;
        transform: scaleY(0);
        transform-origin: bottom;

        .ondulations {
            left: 0;
            right: auto;
            animation-name: wave, wave_end;
            animation-play-state: paused, paused;
            animation-iteration-count: infinite, 1;
            animation-timing-function: linear;
            animation-fill-mode: both;
            animation-duration: 3s, 1s;
        }

    }

    &.step1 .wave1,
    &.step2 .wave2,
    &.step3 .wave3,
    &.step4 .wave4,
    &.step5 .wave5,
    &.step6 .wave6,
    &.step7 .wave7,
    &.step8 .wave8,
    &.step9 .wave9 {
        transform: scaleY(1.1);

        .ondulations {
            animation-play-state: running, paused;
        }
    }

    &.step1.step2 .wave1,
    &.step2.step3 .wave2,
    &.step3.step4 .wave3,
    &.step4.step5 .wave4,
    &.step5.step6 .wave5,
    &.step6.step7 .wave6,
    &.step7.step8 .wave7,
    &.step8.step9 .wave8,
    &.step9 .wave9 {
        transform: scaleY(1);
    }

    &.step4 .tache1,
    &.step5 .tache4,
    &.step6 .tache2,
    &.step7 .tache3,
    &.step8 .tache5,
    &.step9 .tache6 {
        animation-play-state: running, paused;
    }

    &.step10 {
        .chantilly {
            transform: scaleY(1) scaleX(1);
            opacity: 1;
        }

        .chantilly_under {
            transform: scaleY(1);
            opacity: 0.92;

            &+.chantilly_under {
                transform: scaleX(0.9) scaleY(1.2) rotate(2deg);
                opacity: 0.25;
            }
        }

        .ombre_bottom {

            opacity: 0.6;
        }

    }

    &.step11 .confetti {
        animation-play-state: running;
    }

    &.end {
        animation: shakeX 500ms linear;
        animation-iteration-count: 2;

        .wave {
            .ondulations {
                animation-play-state: paused, running;
            }
        }

        .paille {
            animation: paille 0.75s ease-in forwards 1.5s;
        }

        .tache {
            animation-play-state: paused, running !important;
            opacity: 0.15
        }
    }

}

@keyframes wave {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(ms.$width * -2.62);
    }
}

@keyframes wave_end {
    to {
        transform: translate3d(ms.$width * -6.4, 0, 0);
    }
}


@keyframes tache {
    0% {
        opacity: 0.025;
        transform: translate3D(90px, 0px, 0);
    }

    20% {
        opacity: 0.1;
        transform: translate3D(0px, 15px, 0);
    }

    50% {
        opacity: 0.025;
        transform: translate3D(-90px, 5px, 0);
    }

    75% {
        opacity: 0.0125;
        transform: translate3D(0px, -20px, 0);
    }

    100% {
        opacity: 0.025;
        transform: translate3D(100px, 0px, 0);
    }

}

@keyframes tache_end {
    0% {
        opacity: 0;
        transform: translate3D(var(--posYend), 0px, 0);
    }

    100% {
        opacity: 0.085;
        transform: translate3D(var(--posYend), 0px, 0);
    }

}

@keyframes shakeX {

    from,
    to {

        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes confetti {
    0% {
        opacity: 0;
        transform: translate3D(0, -350px, 0);
    }

    25% {
        opacity: 0.75;

    }

    50% {
        opacity: 0.15;

    }

    100% {
        opacity: 1;
        transform: translate3D(0, 0, 0);
    }

}

@keyframes paille {
    0% {
        opacity: 0;
        transform: rotate(-30deg) translate3d(0, ms.$width * -1, 0);
    }

    20% {
        opacity: 1;
        transform: rotate(-30deg) translate3d(0, 10px, 0);
    }

    35% {
        opacity: 1;
        transform: rotate(-30deg) translate3d(0, 0, 0);
    }

    55% {
        opacity: 1;
        transform: rotate(-30deg) translate3d(0, 5px, 0);
    }

    70% {
        opacity: 1;
        transform: rotate(-30deg) translate3d(0, 0, 0);
    }

    100% {
        opacity: 1;
        transform: rotate(-34deg) translate3d(0, 0, 0);
    }

}